.header{
    background-color: #c6d8d3;
    background-image: radial-gradient(circle  at  80% 100%, rgba(216, 30, 91, 0.7) 33%, transparent 33%),
    radial-gradient(circle  at  0% 0%, rgba(216, 30, 91, 0.7) 20%, transparent 20%);
    
    /* background-image: linear-gradient( to bottom right, #C6D8D3 35%, #D81E5B); */
    width: 100vw;
    height: 100vh;
    padding-top:60px;
} 



.logocenter
{
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .logoCenter {
    animation: logoCenter-spin infinite 3s linear;
    }
}

@keyframes logoCenter-spin {
    from {
      transform: rotateY(0deg);
    }
    80% {
      transform: rotateY(90deg);
    }
    85%{
        transform: rotateY(180deg);
    }
    to {
      transform: rotateY(360deg);
    }
  }

.downArrow
  {
    height: 50px;
    width: auto;
    margin-top: -40px;
  }

.scroll
{
  position: absolute;
  bottom: 0;
  left: 50%;
  color: #c6d8d3;
}

.scroll
{
  cursor: pointer;
}