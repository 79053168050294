.contact
{
    background-color: #c6d8d3;
    background-image: radial-gradient(circle  at  80% 100%, rgba(216, 30, 91, 0.7) 33%, transparent 33%),
    radial-gradient(circle  at  0% 0%, rgba(216, 30, 91, 0.7) 20%, transparent 20%);
    
    /* background-image: linear-gradient( to bottom right, #C6D8D3 35%, #D81E5B); */
    width: 100%;
    height: 100vh;
    padding-top:60px;
}

.header
{
    font-size: 10em;
    color:#331832;
}

.icons
{
    height: 100px;
    width: auto;
}

.contactList
{
    list-style: none;
    display: flex;
    gap:10px;
}

.contactActions
{
    display:flex;
    justify-content:space-around;
}

@media only screen and (max-width: 600px) {
    .header{
        font-size: 5em;
    }

    .icons
    {
        height: 50px;
        width: auto;
        margin-top:100px;
    }

    .contact{
        padding-top:90px;
    }
}

@media only screen and (max-width: 450px) {
    .header{
        font-size: 3em;
    }
}